import React from 'react';
import styles from './Services.module.css';

const Services = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  return (
    <div ref={ref} className={styles.container}>
      <h1 className={styles.heading}>Services</h1>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div className={styles.background}></div>
          <h2 className={styles.title}>Blockchain Development</h2>
          <p>Explore the potential of blockchain with our specialized services, including custom blockchain solutions, smart contract development, and more.</p>
          <ul className={styles.list}>

            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Architecture design</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>DAPPS</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Smart contracts</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Seamless integrations</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>SDKs</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Blockchain payments</li>
          </ul>
        </div>
        <div className={styles.card}>
          <div className={styles.background}></div>
          <h2 className={styles.title}>Backend Development</h2>

          <p>Power your applications with our robust backend development services. We ensure your systems are scalable, secure, and integrated seamlessly with your business operations.</p>
          <ul className={styles.list}>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Architecture design</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Scalable and secure</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>API development and integrations</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Database design and management</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Cloud Computing Services</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>CI/CD</li>
          </ul>
        </div>
        <div className={styles.card}>
          <div className={styles.background}></div>
          <h2 className={styles.title}>Frontend Development</h2>
          <p>Create captivating user experiences with our frontend development services. We focus on responsive, intuitive designs that engage and retain your audience.</p>
          <ul className={styles.list}>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Prototype and Wireframe Design</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>UX</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>UI</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Cross platform</li>
            <li className={styles.listItem}><span className={styles.checkmark}>✔</span>Responsive</li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default Services;
