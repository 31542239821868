import React, { useState } from 'react';
import styles from './Navbar.module.css';

type NavbarProps = {
  onContactClick: () => void;
  onServicesClick: () => void;
  onWhyClick: () => void;
};

const Navbar: React.FC<NavbarProps> = ({ onContactClick, onServicesClick, onWhyClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.hamburger} onClick={toggleMenu}>
        {isOpen ? '\u00D7' : '\u2630'} {/* Unicode for 'X' and hamburger icon */}
      </div>
      <nav className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
        <a onClick={onWhyClick} className={styles.navLink}>About</a>
        <a onClick={onServicesClick} className={styles.navLink}>Services</a>
        <a onClick={onContactClick} className={styles.navLink}>Contact</a>
      </nav>
    </div>
  );
};

export default Navbar;
