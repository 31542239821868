import React from 'react';
import styles from './Why.module.css';

const Why= React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.background}></div>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>
          <span className={styles.mainText}>Why</span>
          <span className={styles.secondaryText}> choose</span><br />
          <span className={styles.mainText}>Pluxtech?</span>
        </h1>
      </div>
      <div className={styles.content}>
        <div className={styles.block}>
          <h2 className={styles.subtitle}>Our Mission</h2>
          <p>Our mission is to deliver exceptional software development services, from robust backend systems to sleek frontend designs, integrated seamlessly with the revolutionary potential of blockchain technology.</p>
        </div>
        <div className={styles.block}>
          <h2 className={styles.subtitle}>Our Vision</h2>
          <p>To be recognized globally as a versatile software development powerhouse, driving business success through technological innovation and expertise.</p>
        </div>
        <div className={styles.block}>
          <h2 className={styles.subtitle}>Our Team</h2>
          <p>Our expert team is a dynamic blend of blockchain enthusiasts, seasoned backend developers, and creative frontend designers, all working together to push the boundaries of what's possible in the digital realm.</p>
        </div>
      </div>
    </div>
  );
});

export default Why;