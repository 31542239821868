import React, { useRef } from 'react';
import './App.css';
import Header from './components/header';
import Why from './components/why';
import Services from './components/services';
import Footer from './components/footer';
import Navbar from './components/navbar';
import ContactForm from './components/contactForm';

function App() {
  const contactFormRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);
  const whyRef = useRef<HTMLDivElement>(null);

  const scrollToContactForm = () => {
    contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToWhy = () => {
    whyRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <Navbar onContactClick={scrollToContactForm} onServicesClick={scrollToServices} onWhyClick={scrollToWhy}></Navbar>
      <Header onContactClick={scrollToContactForm}></Header>
      <Why ref={whyRef}></Why>
      <Services ref={servicesRef}></Services>
      <ContactForm ref={contactFormRef}></ContactForm>
      <Footer></Footer>
    </div>
  );
}

export default App;
