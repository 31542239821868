import React from "react";
import styles from "./Header.module.css";
import blueDot from "../assets/blue-dot.svg";
import logo from "../assets/logo.webp";
import x from "../assets/x.svg";
import goldDot from "../assets/gold-dot.svg";
import background from "../assets/background.webp";

type HeaderProps = {
  onContactClick: () => void;
};

const Header: React.FC<HeaderProps> = ({ onContactClick }) => {
  return (
    <div className={styles.divider}>
      <img className={styles.backgroundImage} src={background} alt="background" loading="lazy"></img>
      <div className={styles.blueDotLeftContainer}>
        <img src={blueDot} className={styles.blueDotLeft} alt="gold dot"></img>
      </div>
      <div className={styles.xLeftContainer}>
        <img src={x} className={styles.xLeft} alt="X graphic"></img>
      </div>
      <div className={styles.goldDotLeftContainer}>
        <img src={goldDot} className={styles.goldDotLeft} alt="blue dot"></img>
      </div>
      <div className={styles.goldDotRightContainer}>
        <img src={goldDot} className={styles.goldDotRight} alt="gold dot"></img>
      </div>
      <div className={styles.topDividerContainer}>
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: "#22333b", width: "100vw", height: 50 }}
        >
          <path
            d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
            opacity=".25"
          />
          <path
            d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
            opacity=".5"
          />
          <path d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />
        </svg>
      </div>
      <header className={styles.header}>
        <div className={styles.content}>
          <img src={logo} alt="Pluxtech Logo" className={styles.logo} loading="lazy"/>
          <p className={styles.subtitle}>
            From Blockchain Innovation to Full-Stack Development, Pluxtech is
            Your Gateway to Technological Excellence.
          </p>
          <a onClick={onContactClick} className={styles.button}>
            Contact us
          </a>
        </div>
      </header>
      <div className={styles.blueDotRightContainer}>
        <img src={blueDot} className={styles.blueDotRight} alt="blue dot"></img>
      </div>
      <div className={styles.xRightContainer}>
        <img src={x} className={styles.xRight} alt="X graphic"></img>
      </div>

      <div className={styles.bottomDividerContainer}>
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            fill: "#f9f9f9",
            width: "100vw",
            height: 50,
            transform: "rotate(180deg)",
          }}
        >
          <path
            d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
            opacity=".25"
          />
          <path
            d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
            opacity=".5"
          />
          <path d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />
        </svg>
      </div>
    </div>
  );
};

export default Header;
