import React, { useState } from 'react';
import styles from './ContactForm.module.css'; 

const ContactForm = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const mailtoLink = `mailto:info@pluxtech.com?subject=Contact from ${name}&body=${message}`;
    window.open(mailtoLink, '_blank');
  };

  return (
    <div ref={ref} className={styles.contactFormContainer}>
      <div className={styles.contactDetails}>
        <div className={styles.contactText}>
          <h2 className={styles.contactHeading}>Let's build something cool together</h2>
          <p className={styles.contactInfo}>Córdoba, Spain</p>
          <p className={styles.contactInfo}>info@pluxtech.com</p>
          <div className={styles.socialMediaLinks}>
          </div>
        </div>
      </div>

      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label className={styles.interestLabel}>I'm interested in...</label>
          </div>
          <input 
            type="text" 
            placeholder="Your name" 
            className={styles.formInput} 
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <textarea 
            placeholder="Your message" 
            className={styles.formTextarea} 
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type="submit" className={styles.submitButton}>Send Message</button>
        </form>
      </div>
    </div>
  );
});

export default ContactForm;
