import React from "react";
import styles from "./Footer.module.css";
import x from "../assets/x.svg";

// Sample social media icon components
const TwitterIcon = () => (
  <a href="https://twitter.com" className={styles.icon}>
    🐦
  </a>
);
const InstagramIcon = () => (
  <a href="https://instagram.com" className={styles.icon}>
    📸
  </a>
);
const FacebookIcon = () => (
  <a href="https://facebook.com" className={styles.icon}>
    📘
  </a>
);
const LinkedInIcon = () => (
  <a href="https://linkedin.com" className={styles.icon}>
    🔗
  </a>
);

const Footer: React.FC = () => {
  return (
    <div className={styles.dividerContainer}>
      <div className={styles.divider}>
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.dividerShape}
        >
          <defs>
            <linearGradient
              id="dividerGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="rgba(34,51,59,1)" />
              <stop offset="66%" stopColor="rgba(58,99,101,1)" />
            </linearGradient>
          </defs>
          <path
            d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
            opacity=".25"
            fill="url(#dividerGradient)"
          />
          <path
            d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
            opacity=".5"
            fill="url(#dividerGradient)"
          />
          <path
            d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z"
            fill="url(#dividerGradient)"
          />
        </svg>
      </div>
      <footer className={styles.footer}>
        {/* <div className={styles.linkColumns}>
          <div className={styles.linkColumn}>
            <a href="/about" className={styles.link}>
              About
            </a>
            <a href="/team" className={styles.link}>
              Team
            </a>
            <a href="/vision" className={styles.link}>
              Vision
            </a>
            <a href="/history" className={styles.link}>
              History
            </a>
          </div>
          <div className={styles.linkColumn}>
            <a href="/services" className={styles.link}>
              Services
            </a>
            <a href="/blockchain" className={styles.link}>
              Blockchain solutions
            </a>
            <a href="/backend" className={styles.link}>
              Backend development
            </a>
            <a href="/frontend" className={styles.link}>
              Frontend development
            </a>
          </div>
          <div className={styles.linkColumn}>
            <a href="/support" className={styles.link}>
              Support
            </a>
            <a href="/faq" className={styles.link}>
              FAQ
            </a>
            <a href="/contact" className={styles.link}>
              Contact
            </a>
            <a href="/privacy" className={styles.link}>
              Privacy
            </a>
          </div>
        </div> */}
        {/* <div className={styles.socials}>
          <TwitterIcon />
          <InstagramIcon />
          <FacebookIcon />
          <LinkedInIcon />
        </div> */}
        <div className={styles.copyright}>
          © 2024 Pluxtech - Crafting The Future
        </div>
        {/* <div className={styles.xContainer}>
          <img src={x} alt="X graphic" className={styles.x} />
        </div> */}
      </footer>
    </div>
  );
};

export default Footer;
